import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Login } from "@pypestream/bot-user-session";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import Loader from "Manager/Components/Loader";
import WebChatPreview from "Manager/Components/modals/WebChatPreview";
import NotFound from "Manager/Components/NotFound";
import PrivateRoute from "./PrivateRoute";
import ManagerRoute from "./ManagerRoute";
import Logout from "./Logout";

import config from "./config";
import { isEmpty } from "lodash";

const Unauthorized = React.lazy(() =>
  import("Manager/Components/Unauthorized")
);
const Customers = React.lazy(() => import("Manager/Components/Customers"));
const Customer = React.lazy(() => import("Manager/Components/Customer"));
const Home = React.lazy(() => import("Manager/Components/Home"));
const Bot = React.lazy(() => import("Manager/Components/Bot"));
const CreateOrEditInterface = React.lazy(() =>
  import("./Manager/Components/Bot/ChannelsOrInterfaces/CreateOrEditInterface")
);
const SelectChannelInterface = React.lazy(() =>
  import("./Manager/Components/Bot/SelectChannel/SelectChannelInterface")
);
const EditActions = React.lazy(() =>
  import("./Manager/Components/Bot/EditActions")
);
const Solution = React.lazy(() => import("Solution"));
const Manage = React.lazy(() => import("Manager/Components/Bot/Manage"));
const Analytics = React.lazy(() => import("Manager/Components/Bot/Analytics"));
const UserProfile = React.lazy(() => import("Manager/Components/UserProfile"));

const RouterComponent = ({
  flags,
  authenticationError,
  isAuthenticated,
  initialEntries,
}) => {
  const lazyRoutes = [
    { path: "/unauthorized", exact: false, component: Unauthorized },
    { path: "/user-profile", exact: true, component: UserProfile },
    { path: "/customers", exact: false, component: Customers },
    { path: "/:customerId", exact: true, component: Home },
    { path: "/", exact: true, component: Home },
    { path: "/customer/:customerId", exact: false, component: Customer },
    {
      path: "/bot/:customerId/:botId",
      exact: true,
      component: Bot,
      previousLocation: window.location.pathname,
    },
    {
      path: "/bot/:customerId/:botId/createNewInterface",
      exact: false,
      component: CreateOrEditInterface,
    },
    {
      path: "/bot/:customerId/:botId/editInterface/:conversationId",
      exact: false,
      component: CreateOrEditInterface,
    },
    {
      path: "/bot/:customerId/:botId/channel",
      exact: false,
      component: SelectChannelInterface,
    },
    {
      path: "/bot/:customerId/:botId/channel/:channelId",
      exact: false,
      component: SelectChannelInterface,
    },
    {
      path: "/bot/:customerId/:botId/interface/:interfaceId",
      exact: false,
      component: SelectChannelInterface,
    },
    {
      path: "/bot/:customerId/:botId/editAction/:action",
      exact: false,
      component: EditActions,
    },
    {
      path: "/solution/:customerId/:botId",
      exact: false,
      component: Solution,
      previousLocation: window.location.pathname,
      private: true,
    },
    {
      path: "/bot/:customerId/:botId/manage",
      exact: false,
      component: Manage,
    },
    {
      path: "/bot/:customerId/:botId/build",
      exact: true,
      component: Solution,
      fullWidth: true,
    },
    {
      path: `/bot/:customerId/:botId/${
        flags.pe21804 ? "analytics" : "monitor"
      }`,
      exact: true,
      component: Analytics,
    },
  ];

  return (
    <Router basename={config.homepage} initialEntries={initialEntries}>
      <React.Suspense fallback={<Loader />}>
        <Switch>
          <Route
            path="/login"
            render={(props) => (
              <Login
                {...props}
                coywolf={flags.coywolfLogin}
                error={authenticationError}
                flags={flags}
              />
            )}
          />
          <Logout path="/logout" />
          <Route
            path="/activate/:token?"
            exact={false}
            render={(props) => (
              <Login
                {...props}
                coywolf={flags.coywolfLogin}
                activate={true}
                title="Pypestream"
              />
            )}
          />
          {lazyRoutes.map((route) => {
            if (route.private) {
              return (
                <PrivateRoute
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                  isAuthenticated={isAuthenticated}
                  authenticationError={authenticationError}
                />
              );
            }
            return (
              <ManagerRoute
                key={route.path}
                path={route.path}
                exact={route.exact}
                fullWidth={route.fullWidth}
                component={route.component}
                isAuthenticated={isAuthenticated}
                authenticationError={authenticationError}
              />
            );
          })}
          {!isEmpty(flags) && <Route component={NotFound} />}
        </Switch>
        <WebChatPreview />
      </React.Suspense>
    </Router>
  );
};

RouterComponent.propTypes = {
  flags: PropTypes.object,
  authenticationError: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  initialEntries: PropTypes.any,
};

RouterComponent.defaultProps = {
  flags: {},
  authenticationError: false,
  isAuthenticated: false,
  initialEntries: [],
};

export default withLDConsumer()(RouterComponent);
